import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { rule, validate, validateAll } from 'indicative';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { useMutation, useLazyQuery } from '@apollo/client';
import PhoneInput, { isSupportedCountry, isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import {
  atLeastOneCoordinatorMessage,
  atLeastOnePhoneNumberRequiredMessage,
  userValidationMessages,
  userValidationRules,
} from '../../../../validations/user/validator';
import { buildValidationErrorMessages } from '../../../../validations/common';
import { digitalrolesDisplay, rolesDisplay, cbAdminRoleProps } from '../../../../constants/roles';
import { getGroupTypes, groupTypesCheck } from '../../../../utils/getGroupTypes';
import { CREATE_USER, UPDATE_USER } from '../../../../apollo/mutations';
import { CHECK_USER_NAME, GET_STAFF } from '../../../../apollo/queries';
import { localStateReducer, parsePhoneNumbers, scrollTo, sortItems } from '../../../../utils/common';
import { useStateValue } from '../../../../context/AppContext';
import { ModalDispatchContext } from '../../../ui/modal/ModalContext';
import {
  getSubmitButtonDisabled,
  getUserNameCheckMsg,
  handleUserNameCheck,
  atLeastOneCoordinator,
} from './StaffForm.service';
import { usePreviousValue } from '../../../../constants/usePreviousValue';
import { useTitle } from '../../../../constants/useTitle';
import Checkbox from '../../../ui/form/Checkbox';
import ErrorMessages from '../../../ui/message/ErrorMessage';
import Input from '../../../ui/form/Input';
import OneButtonModal from '../../../ui/modal/standard/OneButtonModal';
import Select from '../../../ui/form/Select';
import SuccessMessage from '../../../ui/message/SuccessMessage';
import TwoButtonModal from '../../../ui/modal/standard/TwoButtonModal';
import { useNavigate } from 'react-router-dom';
import { BlackButton, YellowButton } from '@cb/apricot-react';
import { isCBAdmin } from '../../../../utils/user';

function StaffForm({ allStaff = [], rooms = [], staff = {}, toggleForm = () => {} }) {
  // Global App state.
  const { orgEvent, user } = useStateValue();
  const dispatchModal = useContext(ModalDispatchContext);
  const [pageTitle, setPageTitle] = React.useState(undefined);
  const rolesDisplayLocal = orgEvent?.dapInd ? digitalrolesDisplay : rolesDisplay;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (staff.username) {
      setPageTitle(`Edit - ${staff.username}`);
    } else {
      setPageTitle('Staff');
    }
  }, [staff.username]);

  useTitle(pageTitle);

  function generateRoomOptions() {
    const groupTypes = getGroupTypes(orgEvent);
    let displayRooms = rooms.map((room) => ({
      value: room.id,
      label: `${room.title}${
        room.groupTypes[0] ? ` - ${room.groupTypes[0]}: ${groupTypes[room.groupTypes[0]]?.label}` : ''
      }`,
    }));

    displayRooms = sortItems(
      displayRooms,
      [
        {
          name: 'title',
          order: 'asc',
        },
      ],
      'natural'
    );

    return [
      {
        label: 'Not assigned',
        value: '',
      },
      ...displayRooms,
    ];
  }

  function generateRoleOptions() {
    let displayItem;
    const displayRoles = [];

    rolesDisplayLocal.forEach((item) => {
      displayItem = {
        label: item.title,
        value: item.value,
      };

      if (!item.hide) {
        displayRoles.push(displayItem);
      } else if (isCBAdmin(user)) {
        // CBAdmins can switch between any roles
        displayRoles.push(displayItem);
      }
    });

    return [
      {
        label: 'Not assigned',
        value: '',
      },
      ...displayRoles,
    ];
  }

  function generateActiveOptions() {
    return [
      {
        label: 'Not granted',
        value: 'false',
      },
      {
        label: 'Granted',
        value: 'true',
      },
    ];
  }

  // Validate every time a field changes.
  async function changeFormState(val, e) {
    // handle variable inputs to this function for the Apricot 4 transition
    if (!e) {
      e = val;
    }
    const name = e.target.name;

    // Make a copy to not mutate the original.
    const validationRules = {
      ...localState.userValidation,
    };
    let value = e.target.value;

    // Strip out form prefix.
    const newField = name.replace(`${UI_PREFIX}-`, '');

    if (newField === 'forceUser') {
      value = e.target.checked;
    }

    // Add exception for work extension. Limit to 5 characters, since type="number" has no maxLength.
    if (newField === 'phoneWorkExt' && value.length > 15) {
      value = value.substring(0, 15);
    }

    const newObj = {
      [newField]: value,
    };

    // Merge in current state.
    const updatedForm = {
      ...localState.form,
      ...newObj,
    };

    // Let's reset the form error for this field until it gets re-validated.
    // If this is the work extension, just validate the work phone and clear any errors if needed.
    const formErrors = {
      ...localState.formErrors,
      [newField]: '',
      phoneWork:
        newField === 'phoneWorkExt' && value === ''
          ? ''
          : newField === 'phoneWorkExt' && value !== '' && !localState.form.phoneWork
          ? 'Work number is required if an extension is given.'
          : localState.formErrors.phoneWork,
    };

    // Make username required if force add is selected.
    if (newField === 'forceUser' && value) {
      validationRules.username = [...userValidationRules.username, rule('required')];
    } else if (newField === 'forceUser' && !value) {
      // Remove "required" validation if needed.
      validationRules.username = [...userValidationRules.username];
      formErrors.username = '';

      if (isEmpty(form.id)) updatedForm.username = '';
    }

    // Add username required if they already had one to start with.
    if (newField === 'username' && !!staff.username) {
      validationRules.username = [...userValidationRules.username, rule('required')];
    }

    // If they picked a room, but no role, make role required.
    if (updatedForm.room || updatedForm.forceUser) {
      validationRules.role = [rule('required')];
    } else {
      // Remove "required" if not assigned to a room and not forcing a user.
      validationRules.role = [];
      formErrors.role = '';
    }

    // Validate this field if it has any rules.
    if (validationRules[newField] !== undefined) {
      await validate(newObj, { [newField]: validationRules[newField] }, userValidationMessages).catch((errors) => {
        errors.forEach((err) => {
          formErrors[err.field] = err.message;
        });
      });
    }

    if (!atLeastOneCoordinator(staff.role, updatedForm.role, allStaff)) {
      formErrors.role = atLeastOneCoordinatorMessage;
    }

    // If they select the Hall Monitor role, unset their room.
    if (newField === 'role' && (value === 'hallMonitor' || value === 'technologyCoordinator')) {
      updatedForm.room = '';
    }

    // If they select a coordinator role, set the access to granted automatically.
    if (newField === 'role' && value === 'admin') {
      updatedForm.active = 'true';
    }

    setLocalState({
      form: updatedForm,
      formErrors,
      formSuccess: '',
      isDirty: true,
      staffEmailSent:
        (newField === 'firstName' || newField === 'lastName' || newField === 'email' || newField === 'role') &&
        !updatedForm.username,
      submitDisabled: false,
      userValidation: validationRules,
    });
  }

  function handleCancel() {
    function handleCancelModalConfirm() {
      if (!staff || !staff.id) {
        toggleForm(false);
      }

      if (staff && staff.id) {
        navigate(`/staff/get/${staff.id}`);
      }
    }

    if (!localState.isDirty) {
      if (!isEmpty(staff) || !staff.id) {
        toggleForm(false);
      } else {
        navigate(`/staff/get/${staff.id}`);
      }
    } else {
      dispatchModal(
        <TwoButtonModal
          body='You’ll lose unsaved changes if you cancel now.'
          modalId='staffFormConfirmation'
          primaryButtonHandler={handleCancelModalConfirm}
          primaryButtonLabel='Yes'
          secondaryButtonLabel='No'
          title='Are you sure you want to cancel?'
          variant='error'
          clickOverlayToClose={false}
        />
      );
    }
  }

  function validateForm(form = {}) {
    const updatedUserId = form.id || '';
    const currentRole = form.role || '';
    const roomAssignedId = form.room || '';

    // Check if two proctors will be in the same room
    if (currentRole === 'proctor' && roomAssignedId) {
      const currentRoom = rooms.find((item) => roomAssignedId === item.id);
      const otherRoomProctors = ((currentRoom && currentRoom.staff) || []).filter(
        (item) => item.role === 'proctor' && item.id !== updatedUserId
      );

      if (!isEmpty(otherRoomProctors)) {
        return () => {
          dispatchModal(
            <OneButtonModal
              body={'Select a different room or a different role and try again.'}
              buttonLabel={'OK'}
              modalId='staffFormError'
              title={'The room you chose already has a room proctor.'}
              variant='error'
            />
          );
        };
      }
    }

    return null;
  }

  const getEmailDomain = (email) => {
    return email?.slice(email.indexOf('@') + 1);
  };

  function mutateUser(form) {
    const handleBusinessError = validateForm(form);
    if (handleBusinessError) {
      handleBusinessError();
      // If this is a new form, create.
    } else if (!form.id || form.id === '') {
      createUser({
        variables: {
          input: {
            ...form,
          },
        },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
        update: (store, { data: { createUser } }) => {
          try {
            // Read the existing data from the local cache using the GET_STAFF query
            const data = store.readQuery({ query: GET_STAFF });
            const newData = {
              ...data,
              viewer: {
                ...data.viewer,
                site: {
                  ...data.viewer.site,
                  staff: [createUser, ...data.viewer.site.staff],
                },
              },
            };
            // Write the updated data back to the local cache for the GET_STAFF query
            store.writeQuery({
              query: GET_STAFF,
              data: newData,
            });
          } catch (e) {
            // Handle errors that might occur during the update process
            console.error('Failed to add staff.', e);
          }
        },
      })
        .then(() => {
          if (!form.id || form.id === '') {
            // Scroll up to the top of the form after they finish.
            scrollTo(UI_PREFIX);

            const formSuccessMessage = form.forceUser
              ? 'This user has been successfully force-added to Test day Toolkit. Instruct the user to sign in to TDTK using testday.collegeboard.org and log in with their CB Professional username and password.'
              : 'You’ve added staff and we’ll send them an email with instructions. Add more staff if you need to.';

            // Reset the form.
            document.getElementById(UI_PREFIX) && document.getElementById(UI_PREFIX).reset();

            // They added a form, so update the state.
            setLocalState({
              form: {
                active: 'false',
                email: '',
                firstName: '',
                forceUser: false,
                id: '',
                lastName: '',
                phone: '',
                phoneHome: '',
                phoneMobile: '',
                phoneWork: '',
                phoneWorkExt: '',
                role: '',
                room: '',
                username: '',
              },
              submitDisabled: true,
              submitErrors: [],
              formSuccess: formSuccessMessage,
            });

            document.getElementById('staff-header')?.scrollIntoView({ behavior: 'smooth' });
          }
        })
        .catch((errors) => {
          const submitErrors = buildValidationErrorMessages(
            errors.graphQLErrors,
            UI_PREFIX,
            userValidationMessages,
            getEmailDomain(form.email)
          );
          setLocalState({
            submitDisabled: true,
            submitErrors,
          });
          document.getElementById('staff-header')?.scrollIntoView({ behavior: 'smooth' });
        });
    } else {
      // Editing User.
      updateUser({
        variables: { input: form },
        refetchQueries: ['inventory', 'getStaff', 'getSiteStats'],
      })
        .then(() => {
          setLocalState({
            submitDisabled: true,
            submitErrors: [],
          });

          dispatchModal(
            <OneButtonModal
              modalId='staffFormSuccess'
              onClose={() => navigate(-1)}
              title={
                localState.staffEmailSent
                  ? 'We saved your changes and sent your staff member a toolkit access email.'
                  : 'Your changes have been saved.'
              }
              variant='success'
            />
          );
        })
        .catch((errors) => {
          const submitErrors = buildValidationErrorMessages(
            errors.graphQLErrors,
            UI_PREFIX,
            userValidationMessages,
            getEmailDomain(form.email)
          );
          setLocalState({
            submitDisabled: true,
            submitErrors,
          });
          document.getElementById('staff-header')?.scrollIntoView({ behavior: 'smooth' });
        });
    }
  }

  function changePhone(name) {
    return async function (value) {
      const newObj = {
        [name]: value,
      };

      // Merge in current state.
      const updatedForm = {
        ...localState.form,
        ...newObj,
      };

      // Let's reset the form error for this field until it gets re-validated.
      let formErrors = {
        ...localState.formErrors,
        [name]: '',
      };

      let validatedResult;

      // Validate this field if it has any rules.
      if (
        localState.userValidation[name] !== undefined &&
        (name === 'phoneHome' || name === 'phoneMobile' || name === 'phoneWork')
      ) {
        validatedResult = await validatePhone({
          phoneHome: updatedForm.phoneHome,
          phoneMobile: updatedForm.phoneMobile,
          phoneWork: updatedForm.phoneWork,
        });

        formErrors = {
          ...formErrors,
          ...validatedResult,
        };

        // Manually clear out any false errors.
        if (newObj.phoneMobile || newObj.phoneHome || newObj.phoneWork) {
          if (formErrors.phoneMobile === atLeastOnePhoneNumberRequiredMessage) {
            formErrors.phoneMobile = '';
          }
          if (formErrors.phoneHome === atLeastOnePhoneNumberRequiredMessage) {
            formErrors.phoneHome = '';
          }
          if (formErrors.phoneWork === atLeastOnePhoneNumberRequiredMessage) {
            formErrors.phoneWork = '';
          }
        }
      }

      setLocalState({
        form: updatedForm,
        formErrors,
        formSuccess: '',
        staffEmailSent: !updatedForm.username,
        submitDisabled: false,
        isDirty: true,
      });
    };
  }

  async function validatePhone(phoneObj = {}) {
    const formErrors = {};

    await validateAll(
      phoneObj,
      {
        phoneHome: localState.userValidation.phoneHome,
        phoneMobile: localState.userValidation.phoneMobile,
        phoneWork: localState.userValidation.phoneWork,
      },
      userValidationMessages
    ).catch((errors) => {
      errors.forEach((err) => {
        formErrors[err.field] = err.message;
      });
    });

    // Validate the phone number itself.
    Object.keys(phoneObj).forEach((p) => {
      // Check each phone number with the validator from the phone input library.
      if (phoneObj[p] && !isValidPhoneNumber(phoneObj[p])) {
        formErrors[p] = 'Please enter a valid phone number.';
      }
    });

    return formErrors;
  }

  async function handleSubmitPrecheck(e) {
    const {
      form: { forceUser },
    } = localState;
    e && e.preventDefault && e.preventDefault();
    if (forceUser && localState?.form?.role === 'proctor') {
      dispatchModal(
        <TwoButtonModal
          body={
            <p className='mb-4' key='active-changed-msg-2'>
              If you grant this staff member access to the Test Day Toolkit, they will see sensitive student
              information.
              <br />
              <br />
              Tip: Wait until test day to grant access.
            </p>
          }
          modalId='forceAddProctorToAdmin'
          primaryButtonHandler={handleSubmit}
          secondaryButtonHandler={handleCancel}
          primaryButtonLabel='Confirm'
          variant='success'
        />
      );
    } else {
      await handleSubmit(e);
    }
  }

  async function handleSubmit(e) {
    e && e.preventDefault && e.preventDefault();

    setLocalState({
      submitDisabled: true,
    });

    // Convert the string back to boolean for saving.
    const active = localState.form.active === 'true';
    const activeStatusChanged = active !== staff.active;
    let body = [];

    if (activeStatusChanged) {
      body = active ? (
        <p className='mb-4' key='active-changed-msg-1'>
          If you grant this staff member access to the Test Day Toolkit, they will see sensitive student information.
          <br />
          <br />
          Tip: Wait until test day to grant access.
        </p>
      ) : (
        <p className='mb-4' key='active-changed-msg-2'>
          You&rsquo;re about to revoke this staff member&rsquo;s access—they won’t be able use Test Day Toolkit.
        </p>
      );
    }

    const form = {
      ...localState.form,
      active,
      email: (localState?.form?.email || '').trim(),
      firstName: (localState?.form?.firstName || '').trim(),
      lastName: (localState?.form?.lastName || '').trim(),
      username: (localState?.form?.username || '').toUpperCase().trim(),
    };

    let hasErrors = false;
    let formErrors = {};
    let firstError;
    const phoneArr = [];
    const workExt = get(form, 'phoneWorkExt', '');

    await validateAll(form, localState.userValidation, userValidationMessages).catch((errors) => {
      hasErrors = true;
      errors.forEach((err) => {
        formErrors[err.field] = err.message;
      });
    });

    if (!atLeastOneCoordinator(staff.role, form.role, allStaff)) {
      hasErrors = true;
      formErrors.role = atLeastOneCoordinatorMessage;
    }

    // Re-validate all phone numbers if they exist.
    const validatedResult = await validatePhone({
      phoneHome: form.phoneHome,
      phoneMobile: form.phoneMobile,
      phoneWork: form.phoneWork,
    });

    formErrors = {
      ...formErrors,
      ...validatedResult,
    };

    // Now that the phones are validated, combine them into 1 phone field.
    if (form.phoneMobile) {
      if (!formErrors.phoneMobile) {
        phoneArr.push({
          type: 'mobile',
          phoneNumber: form.phoneMobile,
        });
      } else {
        hasErrors = true;
      }
    }

    if (form.phoneHome) {
      if (!formErrors.phoneHome) {
        phoneArr.push({
          type: 'home',
          phoneNumber: form.phoneHome,
        });
      } else {
        hasErrors = true;
      }
    }

    if (form.phoneWork) {
      if (!formErrors.phoneWork) {
        if (workExt) {
          phoneArr.push({
            type: 'work',
            phoneNumber: form.phoneWork,
            ext: workExt,
          });
        } else {
          phoneArr.push({
            type: 'work',
            phoneNumber: form.phoneWork,
          });
        }
      } else {
        hasErrors = true;
      }
    }

    const phoneStringified = JSON.stringify(phoneArr);

    // Remove these props from the object that's going to finally submit. We have the phone stringified.
    delete form.phoneMobile;
    delete form.phoneHome;
    delete form.phoneWork;
    delete form.phoneWorkExt;

    function handleModalConfirm() {
      dispatchModal(null);
      mutateUser({
        ...form,
        phone: phoneStringified,
      });
    }

    // Process the user if we don't have errors.
    if (!hasErrors) {
      // Check if they are adding or updating.
      if (form.id) {
        // Updating. Check their Active status has changed.
        if (activeStatusChanged) {
          dispatchModal(
            <TwoButtonModal
              body={body}
              modalId='staffFormConfirmation'
              primaryButtonHandler={handleModalConfirm}
              primaryButtonLabel='Confirm'
              variant='success'
            />
          );
        } else {
          // Run staff mutations.
          mutateUser({
            ...form,
            phone: phoneStringified,
          });
        }
      } else {
        // Get rid of "id" for a new form.
        delete form.id;
        mutateUser({
          ...form,
          phone: phoneStringified,
        });
      }
    } else {
      setLocalState({
        submitDisabled: true,
      });
    }

    // If we have any client errors, find the first one and scroll to it.
    if (!isEmpty(formErrors)) {
      firstError = `${UI_PREFIX}-${Object.keys(formErrors)[0]}`;

      if (firstError) {
        // We've failed! Scroll to top so they can see the new errors on the form.
        scrollTo(firstError);
      }
    }

    // We had errors, set the state.
    setLocalState({
      formErrors,
    });
  }

  function getDefaultCountry(isoCountryCode) {
    return isSupportedCountry(isoCountryCode) ? isoCountryCode : 'US';
  }

  // We have to parse out old phone numbers or keep new ones. So let's do that if we're using incoming data.
  const phone = parsePhoneNumbers(staff.phone);

  const mobilePhone = phone.find((p) => p.type === 'mobile');
  const homePhone = phone.find((p) => p.type === 'home');
  const workPhone = phone.find((p) => p.type === 'work');
  const phoneWorkExt = workPhone && workPhone.ext ? workPhone.ext : '';

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    elementInvalid: {},
    form: {
      active: !isEmpty(staff) ? staff.active?.toString() : 'false',
      confirmed: !isEmpty(staff) ? staff.confirmed : false,
      email: !isEmpty(staff) ? staff.email : '',
      firstName: !isEmpty(staff) ? staff.firstName : '',
      forceUser: !isEmpty(staff) ? staff.forceUser : false,
      id: !isEmpty(staff) ? staff.id : '',
      phoneMobile: mobilePhone ? mobilePhone.phoneNumber : '',
      phoneHome: homePhone ? homePhone.phoneNumber : '',
      phoneWork: workPhone ? workPhone.phoneNumber : '',
      phoneWorkExt,
      lastName: !isEmpty(staff) ? staff.lastName : '',
      phone: !isEmpty(staff) ? staff.phone : '',
      role: !isEmpty(staff) ? staff.role : '',
      room: !isEmpty(staff.room) ? staff.room.id : '',
      username: !isEmpty(staff) ? staff.username : '',
      welcomed: !isEmpty(staff) ? staff.welcomed : false,
    },
    formErrors: {
      active: '',
      email: '',
      firstName: '',
      id: '',
      lastName: '',
      phone: '',
      role: '',
      room: '',
      username: '',
    },
    formSuccess: '',
    isDirty: false,
    staffEmailSent: false,
    submitDisabled: true,
    submitErrors: [],
    userValidation: {
      ...userValidationRules,
      role: staff && staff.room ? [...userValidationRules.role, rule('required')] : [...userValidationRules.role],
      username:
        staff && staff.username
          ? [...userValidationRules.username, rule('required')]
          : [...userValidationRules.username],
    },
    wasActive: !isEmpty(staff) ? staff.active.toString() : 'false',
  });

  const {
    form: { forceUser },
  } = localState;
  const prevForceUser = usePreviousValue(forceUser);

  const [getUserName, { data: { checkUserName = null, duplicateUserName = false, siteStaffList = false } = {} }] =
    useLazyQuery(CHECK_USER_NAME);

  React.useEffect(() => {
    const shouldUpdate = prevForceUser === undefined || prevForceUser !== forceUser;
    if (
      forceUser &&
      shouldUpdate &&
      isCBAdmin(user) &&
      !isEmpty(localState.form.id) &&
      !isEmpty(localState.form.username)
    ) {
      getUserName({
        variables: {
          userName: localState.form.username,
        },
      });
    }
  }, [forceUser, getUserName, isCBAdmin(user), localState, prevForceUser]);

  // Apollo.
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  const UI_PREFIX = 'staff-form';

  const { form, formErrors, formSuccess, submitDisabled, submitErrors } = localState;

  const activeOptions = generateActiveOptions();
  const roleOptions = generateRoleOptions();
  const roomOptions = generateRoomOptions();

  return (
    <form
      className={`cb-form mb-4 ${form.id ? 'col-md-8 col-lg-6' : ''}`}
      id={UI_PREFIX}
      tabIndex='-1'
      onSubmit={handleSubmitPrecheck}
    >
      <ErrorMessages errorMessages={submitErrors} />

      <SuccessMessage successMessage={formSuccess} />

      <p className='mb-4'>* = Required</p>
      {isCBAdmin(user) ? (
        <div className='mb-4'>
          <Checkbox
            label='Force this user into Test day Toolkit'
            name={`${UI_PREFIX}-forceUser`}
            onChange={changeFormState}
            checked={form.forceUser || false}
            data-automation={`${UI_PREFIX}-forceUser`}
          />
        </div>
      ) : null}

      <div className='mb-4'>
        <Input
          errorMessage={formErrors.firstName}
          floating={false}
          id={`${UI_PREFIX}-firstName`}
          label='First Name'
          name={`${UI_PREFIX}-firstName`}
          onChange={changeFormState}
          required={true}
          value={form.firstName || ''}
        />
      </div>

      <div className='mb-4'>
        <Input
          errorMessage={formErrors.lastName}
          floating={false}
          id={`${UI_PREFIX}-lastName`}
          label='Last Name'
          name={`${UI_PREFIX}-lastName`}
          onChange={changeFormState}
          required={true}
          value={form.lastName || ''}
        />
      </div>

      {isCBAdmin(user)
        ? (!isEmpty(form.id) || form.forceUser) && (
            <div className='mb-4'>
              <Input
                disabled={!isEmpty(form.id) && !isCBAdmin(user)}
                errorMessage={formErrors.username}
                floating={false}
                id={`${UI_PREFIX}-username`}
                data-automation={`${UI_PREFIX}-username`}
                label='College Board Username'
                maxLength={15}
                name={`${UI_PREFIX}-username`}
                onBlur={handleUserNameCheck(isCBAdmin(user), getUserName)}
                onChange={changeFormState}
                required={!!staff.username || localState.form.forceUser}
                value={form.username || ''}
              />
              {!formErrors.username &&
                staff.username !== localState.form.username &&
                getUserNameCheckMsg({
                  checkUserName,
                  duplicateUserName,
                  siteStaffList,
                  username: localState.form.username,
                  formError: formErrors.username,
                })}
            </div>
          )
        : null}

      <div className='mb-4'>
        <Input
          errorMessage={formErrors.email}
          floating={false}
          id={`${UI_PREFIX}-email`}
          label='Email'
          name={`${UI_PREFIX}-email`}
          onChange={changeFormState}
          required={true}
          value={form.email || ''}
        />
      </div>

      <fieldset className='mb-4 required' style={{ borderBottom: '1px solid #e5e5e5' }}>
        <legend style={{ fontFamily: 'inherit', fontWeight: 'bold', fontSize: 'inherit' }}>
          Phone <span className='cb-red-color'>*</span>
        </legend>
        <p className='mb-4'>At least one phone number is required.</p>

        <div className='row'>
          <div className='col-xs-12'>
            <label htmlFor={`${UI_PREFIX}-phoneMobile`} style={{ fontWeight: 'bold' }}>
              Mobile Phone
            </label>
            <PhoneInput
              className='cb-input'
              autoComplete='off'
              defaultCountry={getDefaultCountry(orgEvent.isoCountryCode)}
              displayInitialValueAsLocalNumber
              flags={flags}
              id={`${UI_PREFIX}-phoneMobile`}
              name={`${UI_PREFIX}-phoneMobile`}
              onChange={changePhone('phoneMobile')}
              smartCaret={true}
              value={form.phoneMobile}
            />
          </div>
        </div>
        {formErrors.phoneMobile && (
          <p
            className='cb-input-helper cb-validation-error'
            id={`${UI_PREFIX}-phoneMobile-error`}
            role='alert'
            aria-atomic='true'
          >
            {formErrors.phoneMobile}
          </p>
        )}

        <div className='row mt-4'>
          <div className='col-xs-12'>
            <label htmlFor={`${UI_PREFIX}-phoneHome`} style={{ fontWeight: 'bold' }}>
              Home Phone
            </label>
            <PhoneInput
              className='cb-input'
              autoComplete='off'
              defaultCountry={getDefaultCountry(orgEvent.isoCountryCode)}
              displayInitialValueAsLocalNumber
              flags={flags}
              id={`${UI_PREFIX}-phoneHome`}
              name={`${UI_PREFIX}-phoneHome`}
              onChange={changePhone('phoneHome')}
              smartCaret={true}
              value={form.phoneHome}
            />
          </div>
        </div>
        {formErrors.phoneHome && (
          <p
            className='cb-input-helper cb-validation-error'
            id={`${UI_PREFIX}-phoneHome-error`}
            role='alert'
            aria-atomic='true'
          >
            {formErrors.phoneHome}
          </p>
        )}

        <div className='row mt-4 mb-4'>
          <div className='col-sm-7'>
            <label htmlFor={`${UI_PREFIX}-phoneWork`} className='mb-0' style={{ fontWeight: 'bold' }}>
              Work Phone
            </label>
            <PhoneInput
              className='cb-input'
              autoComplete='off'
              defaultCountry={getDefaultCountry(orgEvent.isoCountryCode)}
              displayInitialValueAsLocalNumber
              flags={flags}
              id={`${UI_PREFIX}-phoneWork`}
              name={`${UI_PREFIX}-phoneWork`}
              onChange={changePhone('phoneWork')}
              smartCaret={true}
              value={form.phoneWork}
            />

            {formErrors.phoneWork && (
              <p
                className='cb-input-helper cb-validation-error'
                id={`${UI_PREFIX}-phoneWork-error`}
                role='alert'
                aria-atomic='true'
              >
                {formErrors.phoneWork}
              </p>
            )}
          </div>
          <div className='col-sm-5'>
            <Input
              errorMessage={formErrors.phoneWorkExt}
              floating={false}
              id={`${UI_PREFIX}-phoneWorkExt`}
              label='Work Extension'
              maxLength={15}
              minLength={0}
              name={`${UI_PREFIX}-phoneWorkExt`}
              onChange={changeFormState}
              type='tel'
              value={form.phoneWorkExt || ''}
            />
          </div>
        </div>
      </fieldset>

      <div className='mb-4'>
        <Select
          additionalInstructions='We recommend waiting until test day to grant toolkit access. You can assign roles and rooms to staff who don’t have access.'
          errorMessage={formErrors.active}
          id={`${UI_PREFIX}-active`}
          label='Test Day Toolkit Access'
          name={`${UI_PREFIX}-active`}
          onChange={(val) => {
            val = {
              target: {
                name: `${UI_PREFIX}-active`,
                type: 'select',
                value: val,
              },
            };
            changeFormState(val);
          }}
          required={true}
          value={form.active.toString()}
          values={activeOptions}
        />
      </div>

      {
        /**
         * Make this read-only after the user has saved this person as a coordinator.
         * Only CBAdmins are able to see all the roles
         */
        !isEmpty(staff) && staff.role === 'admin' && !isCBAdmin(user) ? (
          <div className='mb-4'>
            <Input
              disabled={true}
              floating={false}
              id={`${UI_PREFIX}-role`}
              label='Role'
              name={`${UI_PREFIX}-role`}
              value='Coordinator'
            />
          </div>
        ) : (
          <div className='mb-4'>
            <Select
              errorMessage={formErrors.role}
              floating={false}
              id={`${UI_PREFIX}-role`}
              label='Role'
              name={`${UI_PREFIX}-role`}
              onChange={(val) => {
                val = {
                  target: {
                    name: `${UI_PREFIX}-role`,
                    type: 'select',
                    value: val,
                  },
                };
                changeFormState(val);
              }}
              required={!!form.room || form.forceUser}
              value={form.role}
              values={roleOptions}
            />
          </div>
        )
      }

      <div className='mb-4'>
        <Select
          disabled={form.role === 'technologyCoordinator' || form.role === 'hallMonitor'}
          errorMessage={formErrors.room}
          id={`${UI_PREFIX}-room`}
          label='Room'
          name={`${UI_PREFIX}-room`}
          onChange={(val) => {
            val = {
              target: {
                name: `${UI_PREFIX}-room`,
                type: 'select',
                value: val,
              },
            };
            changeFormState(val);
          }}
          value={form.room}
          values={roomOptions}
        />
      </div>

      <div className='tdtk-form-group tdtk-primary-secondary display-flex--column-mobile'>
        <YellowButton
          small
          data-automation={`button-${form.id ? 'update-room-form-confirm' : 'add-room-form-confirm'}`}
          disabled={
            submitDisabled ||
            getSubmitButtonDisabled({
              forceUser: !!form.id || forceUser, // We only need the submit disabled for these if they're editing the user (so they have a username) OR they're creating a user
              username: localState.form.username,
              checkUserName,
              duplicateUserName: staff.username !== localState.form.username && duplicateUserName, // Only use this check if they've actually changed the username
              siteStaffList: isEmpty(form.id) && siteStaffList, // Ignore the "already in staff list" check for editing users
            })
          }
          type='submit'
        >
          {form.id ? 'Update Staff' : 'Add Staff'}
        </YellowButton>

        <BlackButton
          small
          onClick={() => {
            if (!submitDisabled) {
              handleCancel();
            } else {
              if (!isEmpty(staff)) {
                navigate(`/staff/get/${staff.id}`);
              } else if (isFunction(toggleForm)) {
                toggleForm(false);
              } else {
                navigate('/staff');
              }
            }
          }}
        >
          Cancel
        </BlackButton>
      </div>
    </form>
  );
}

StaffForm.propTypes = {
  allStaff: PropTypes.array,
  rooms: PropTypes.array,
  staff: PropTypes.object,
  toggleForm: PropTypes.func,
};

export default StaffForm;
