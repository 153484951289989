import React, { useEffect, useState } from 'react';
import { localStateReducer } from '../../../utils/common';
import { mergeMessageIntoData } from './wsMessageMerge';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import type { ServerError, WatchQueryFetchPolicy, FetchMoreQueryOptions } from '@apollo/client';
import { useSocketContext } from './SocketProvider';
import { useStateDispatch } from '../../../context/AppContext';
import ErrorPage from '../../nonDigital/error/ErrorPage';
import has from 'lodash/has';
import Spinner from '../../ui/loading/SpinnerWrapper';
import { ITDTKData, TDTKQuery, TDTKVars } from '../../../apollo/types';

type ChildrenProps<T extends ITDTKData> = (
  wsData: T | undefined,
  refetch: () => void,
  fetchMore: <TFetchData = T, TFetchVars = TDTKVars>(
    fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData>
  ) => void
) => React.ReactNode;

function QueryComponent<T extends ITDTKData>({
  children,
  fetchPolicy = 'network-only',
  pollInterval = 0,
  query,
  skip = false,
  generateFetchMoreOptions,
}: {
  children: ChildrenProps<T>;
  fetchPolicy?: WatchQueryFetchPolicy;
  pollInterval?: number;
  query: TDTKQuery<T>;
  skip?: boolean;
  generateFetchMoreOptions?: (data: T | undefined) => void;
}) {
  // Global App state.
  const dispatch = useStateDispatch();
  const { lastJsonMessage } = useSocketContext();

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    skip,
  });

  const options: {
    fetchPolicy: WatchQueryFetchPolicy;
    pollInterval: number;
    variables?: TDTKVars;
  } = {
    fetchPolicy,
    pollInterval,
  };
  if ('variables' in query) {
    options.variables = query.variables;
  }
  const [getData, { loading, error, data, refetch, fetchMore }] = useLazyQuery<T, TDTKVars>(
    query.specification,
    options
  );

  const [wsData, setWsData] = useState<T>();

  const client = useApolloClient();

  // Skip on re-renders.
  useEffect(() => {
    if (!data && !loading && !localState.skip) {
      getData();
      setLocalState({
        skip: true,
      });
    }

    query.resolvedData = data;
    const { hasUpdated, updatedQuery } = mergeMessageIntoData<T>(query, lastJsonMessage);

    if (hasUpdated) {
      // if our replacement apolloObject is relevant to websocket updates, merge it in
      client.writeQuery({
        query: query.specification,
        data: updatedQuery.resolvedData,
      });
    }
    setWsData(query.resolvedData as T);
  }, [client, getData, lastJsonMessage, data, localState.skip, loading, query]);

  useEffect(() => {
    const options = generateFetchMoreOptions?.(wsData);
    if (options) {
      fetchMore(options);
    }
  }, [wsData, fetchMore, generateFetchMoreOptions]);

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    if (has(error, 'networkError.statusCode') && has(error, 'networkError.result')) {
      const serverError = error.networkError as ServerError;
      if (serverError.statusCode >= 400 && serverError.statusCode <= 499) {
        // All 400 Errors
        if (serverError.result.code === 'auth:missing_user_record') {
          console.error('auth:missing_user_record', serverError.result.code);
          dispatch({ globalError: <ErrorPage errorCode={serverError.result.code} /> });
        } else if (serverError.statusCode === 403) {
          console.error('403', serverError.result);
          dispatch({ globalError: <ErrorPage errorCode='403' /> });
        } else if (serverError.statusCode === 429) {
          dispatch({ globalError: <ErrorPage errorCode='429' /> });
        } else {
          dispatch({ globalError: <ErrorPage errorCode='400' /> });
        }
      } else if (serverError.result.code === 'auth:missing_auth_info') {
        console.error('auth:missing_auth_info', serverError.result.message);
        dispatch({ globalError: <ErrorPage errorCode='400' /> });
      } else if (serverError.statusCode >= 500 && serverError.statusCode <= 599) {
        // All 500 errors
        console.error('serverError.statusCode = ', serverError.statusCode);
        dispatch({ globalError: <ErrorPage errorCode='500' /> });
      }
    } else {
      console.error('Unhandled GraphQL Error!');
      console.error('Failed GQL', query);
      console.error('Error Object', error);
      dispatch({
        globalError: <ErrorPage errorCode='500' />,
      });
    }
  }

  return children(wsData, refetch, fetchMore);
}

export default QueryComponent;
