export const DAP_STUDENT_EXAM_SETUP_STATUS = Object.freeze({
  EXAM_SETUP_COMPLETE: 'exam-setup-completed',
  EXAM_SETUP_STARTED: 'exam-setup-started',
  NOT_STARTED: 'not-started',
  EXAM_CHECKIN_COMPLETED: 'exam-checkin-completed',
});

export const DAP_STUDENT_EXAM_SETUP_STATUS_MAP = {
  [DAP_STUDENT_EXAM_SETUP_STATUS.NOT_STARTED]: 'Not Started',
  [DAP_STUDENT_EXAM_SETUP_STATUS.EXAM_SETUP_STARTED]: 'In Progress',
  [DAP_STUDENT_EXAM_SETUP_STATUS.EXAM_CHECKIN_COMPLETED]: 'Checkin Complete',
  [DAP_STUDENT_EXAM_SETUP_STATUS.EXAM_SETUP_COMPLETE]: 'Complete',
};

export const DAP_STUDENT_TEST_STATUS = Object.freeze({
  WAITING_ROOM_ARRIVAL: 'waiting-room-arrival',
  EXIT_CONFIRMED: 'exit-confirmed',
  NONE: 'none',
  OFFLINE: 'is-offline',
  ONLINE: 'is-online',
  EXAM_SUBMISSION_PENDING: 'exam-submission-pending',
  EXAM_SUBMISSION_COMPLETE: 'exam-submission-complete',
  SECTION_START_TIME: 'section-start-time',
  SECTION_RESUME: 'section-resume',
  EXAM_CHECKIN_STARTED: 'exam-checkin-started',
  SECTION_BREAK: 'section-break',
  STUDENT_SELF_BREAK: 'student-self-break',
  EMERGENCY_BREAK: 'emergency-break',
});
