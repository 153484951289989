import React from 'react';
import PropTypes from 'prop-types';
import { customerSupportInfo } from '../../../constants/customerSupportInfo';
function ErrorPage({ errorCode = '500', route = null }) {
  function resetError() {
    sessionStorage.removeItem('orgEvent');

    window.location.href = '/event';
  }

  let errorMessage = [];
  let errorTitle = '';
  const err = route ? route.errorCode : errorCode;

  switch (err) {
    case 'inactive':
      errorMessage = [
        <div key={1} className='row'>
          <div className='col-sm-6'>
            <h1 className='tdtk-h1' data-automation='heading-h1'>
              You’re all set!
            </h1>
            <p data-automation='error-message-1'>
              You’ll be able to use Test Day Toolkit as soon as your test center coordinator grants you access—probably
              on test day.
            </p>
          </div>
        </div>,
      ];
      errorTitle = null;
      break;

    case '403':
      errorMessage = [
        <p className='mb-4' key={1} data-automation='error-message-1'>
          You can&rsquo;t use Test Day Toolkit yet. Click the button in your access email or paste the personalized link
          into your browser.
        </p>,
        <p className='mb-4' key={2}>
          If you didn&rsquo;t receive an access email, email your test coordinator and ask them to send you one.
        </p>,
        <p className='mb-4' key={3}>
          If you are a coordinator, contact Test Administration (TA) Support at{' '}
          <a
            href={
              'mailto:' + customerSupportInfo.email + '?subject=I%27d%20Like%20Access%20to%20the%20Test%20Day%20Toolkit'
            }
          >
            {customerSupportInfo.email}
          </a>
          or{' '}
          <a href={'tel:+' + customerSupportInfo.domesticPhoneNumber}>
            {customerSupportInfo.domesticPhoneNumberDisplayFormat}
          </a>{' '}
          (domestic) or{' '}
          <a href={'tel:+' + customerSupportInfo.internationalPhoneNumber}>
            {customerSupportInfo.internationalPhoneNumberDisplayFormat}
          </a>{' '}
          (international).
        </p>,
        <p className='mb-4' key={4}>
          <a
            className='cb-btn cb-btn-sm cb-btn-yellow'
            href='mailto:?subject=I%27d%20Like%20Access%20to%20the%20Test%20Day%20Toolkit&amp;body=I%20can%27t%20use%20the%20Test%20Day%20Toolkit%20to%20help%20out%20with%20the%20SAT%20administration.%20Can%20you%20give%20me%20access%3F%0A%0AMy%20email%20is%20%5Binsert%20email%20address%5D%20and%20my%20phone%20number%20is%20%5Binsert%20phone%20number%5D.%0A%0AThank%20you!'
          >
            Email Coordinator
          </a>
        </p>,
      ];
      errorTitle = 'We’re sorry …';
      break;

    case '404':
      errorMessage = [
        <p className='mb-4' key={1} data-automation='error-message-1'>
          The page you&rsquo;re looking for does not exist.
        </p>,
      ];
      errorTitle = '404 Page Not Found';
      break;
    case '429':
      errorMessage = [
        <p className='mb-4' key={1} data-automation='error-message-1'>
          Test Day Toolkit is currently experiencing heavy traffic. Please wait a few minutes and then refresh this
          page. Thanks for your patience.
        </p>,
      ];
      errorTitle = 'We’re sorry';
      break;
    case 'role_mismatch':
      errorMessage = [
        <p className='mb-4' key={1}>
          There is a mismatch in your roles.
        </p>,
      ];
      errorTitle = 'Role Mismatching';
      break;

    case 'cms_missing':
      errorMessage = [
        <p className='mb-4' key={1}>
          CMS content is missing. Please run the cms_import lambda to load CMS content.
        </p>,
      ];
      errorTitle = 'CMS Content Missing';
      break;

    case 'auth:missing_user_record':
      errorMessage = [
        <div key={1}>
          <p className='mb-4'>
            Contact Test Administration Services (TAS). Include your name and title (SAT test center coordinator) and
            your SAT test center number and location.
          </p>
          <h3 className='tdtk-h3'>
            <strong>Email</strong>
          </h3>
          <p className='mb-4'>
            <a href={'mailto:' + customerSupportInfo.email + '?subject=Test%20Day%20Toolkit'}>
              {customerSupportInfo.email}
            </a>
          </p>
          <h3 className='tdtk-h3'>
            <strong>Call</strong>
          </h3>
          <p className='mb-4'>
            <a href={'tel:+' + customerSupportInfo.internationalPhoneNumber}>
              {customerSupportInfo.internationalPhoneNumberDisplayFormat}
            </a>{' '}
            (domestic)
          </p>
          <h5 className='tdtk-h5'>
            <strong>or</strong>
          </h5>
          <p className='mb-4'>
            <a href={'tel:+' + customerSupportInfo.internationalPhoneNumber}>
              {customerSupportInfo.internationalPhoneNumberDisplayFormat}
            </a>{' '}
            (international)
          </p>
          <p className='mb-4'>
            <button
              className='cb-btn cb-btn-sm cb-btn-yellow'
              onClick={resetError}
              data-automation='select-different-administration-button'
            >
              Select a Different Administration
            </button>
          </p>
        </div>,
      ];
      errorTitle = 'We’re sorry …';
      break;

    case 'data:missing_tdtkAsmtType':
      errorMessage = [
        <div key={1}>
          <p className='mb-4'>The event information is incomplete. Please review and update the event details.</p>
          <p className='mb-4'>
            <button
              className='cb-btn cb-btn-sm cb-btn-yellow'
              onClick={resetError}
              data-automation='select-different-administration-button'
            >
              Select a Different Administration
            </button>
          </p>
        </div>,
      ];
      errorTitle = 'We’re sorry …';
      break;

    default:
      errorMessage = [
        <p className='mb-4' key={1} data-automation='error-message-1'>
          There&rsquo;s something wrong on our end and we&rsquo;re working hard to fix it. Come back later and try
          again.
        </p>,
        <p className='mb-4' key={2}>
          <a href='/'>Go to the home page.</a>
        </p>,
      ];
      errorTitle = 'Sorry!';
  }

  // Set analytics tracking.
  const cbTrackData = window.cbTrackData || {};
  cbTrackData.errorCode = errorCode;
  cbTrackData.pageReady = true;

  return (
    <div className='container'>
      <div className='border-gray'>
        {
          errorTitle ? (
            <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
              {errorTitle}
            </h1>
          ) : (
            <span className='d-block my-2' />
          ) // Add a little spacing to the top if we don't pass a normal header.
        }
        {errorMessage}
      </div>
    </div>
  );
}

// Validate props.
ErrorPage.propTypes = {
  errorCode: PropTypes.string,
  route: PropTypes.object,
};

export default ErrorPage;
