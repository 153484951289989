import React, { useEffect } from 'react';
import { isInSchoolAPEvent } from '../../../../../../utils/event';
import { Link } from 'react-router-dom';
import { scrollTo, createCleanMarkup } from '../../../../../../utils/common';
import { useStateValue } from '../../../../../../context/AppContext';
import HelpNav from '../../../../common/HelpNav';
import ProgressBar from '../../../../common/ProgressBar/ProgressBar';
import PropTypes from 'prop-types';
import RoomCode from '../../../../common/RoomCode';
import StepContentPanel from '../../../../common/StepContentPanel';
import StepContentTab from '../../../../common/StepContentTab';
import StudentRoster from './StudentRoster';
import WizardNav from '../../../../common/WizardNav';
import './Attendance.scss';

function getStepDefinitions({ cmsContent, stepNum, isInSchoolAPEvent, room, lastUpdated, refetch, rosterLinkText }) {
  const progressStepperMax = isInSchoolAPEvent ? 7 : 9;
  const stepContent = cmsContent?.steps?.find((n) => n.step_number === stepNum);
  const mainPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(stepContent?.main_panel_content)} />;
  const detailPanelData = <div dangerouslySetInnerHTML={createCleanMarkup(stepContent?.detail_panel_content)} />;

  const content = {
    main_panel_content: mainPanelData,
    main_panel_header: stepContent?.main_panel_header,
    detail_panel_content: detailPanelData,
    detail_panel_header: stepContent?.detail_panel_header,
  };

  const stepComponents = {
    takeAttendance: (
      <div>
        <div className='row row-eq-height px-0 mx-0 wizard-stepper-heading'>
          <div className='col-xs-12 col-md-7 px-0'>
            <ProgressBar currentStep={stepNum} totalSteps={progressStepperMax}></ProgressBar>
          </div>
          <div className='wizard-right-panel col-xs-12 col-md-5 px-0'>
            <div className='wizard-room-code-container ml-auto text-right mr-0'>
              <div className='wizard-room-code-title' data-automation='wizard-room-start-code'>
                Room Code:
              </div>
              <div className='wizard-room-code'>
                <RoomCode code={room.joinCode} />
              </div>
            </div>
          </div>
        </div>
        <StudentRoster
          lastUpdated={lastUpdated}
          refetch={refetch}
          room={room}
          rosterHeading='Take Attendance'
          showDirectionInfo={true}
        />
      </div>
    ),
    readTheScript: (
      <>
        <StepContentPanel
          content={content}
          desc={mainPanelData}
          heading='Next Up: Read the Script'
          img={<div className='script-page-svg attendance-step-illustration-img' />}
          imgDesc='Student Roster'
          nextUpStyle={true}
          progressStepperMax={progressStepperMax}
          room={room}
          showProgressBar={true}
          stepNum={stepNum}
        ></StepContentPanel>
      </>
    ),
    welcome: (
      <>
        <StepContentTab
          heading='Welcome'
          showProgressBar={true}
          content={content}
          invertStyle={true}
          stepNum={stepNum}
          progressStepperMax={progressStepperMax}
          panelHeading='Read aloud to students.'
          rightPanel={
            <Link
              to='#student-roster'
              data-automation='wizard-view-student-roster'
              className='wizard-student-roster-link cb-palette-blue5'
            >
              {rosterLinkText}
            </Link>
          }
          desc={mainPanelData}
        ></StepContentTab>
        <StudentRoster
          lastUpdated={lastUpdated}
          refetch={refetch}
          room={room}
          rosterHeading='Student Roster'
          showDirectionInfo={false}
          showInpage={true}
        />
      </>
    ),
    devicesAndMaterials: (
      <>
        <StepContentTab
          heading='Devices'
          showProgressBar={true}
          content={content}
          invertStyle={true}
          stepNum={stepNum}
          progressStepperMax={progressStepperMax}
          panelHeading='Read aloud to students.'
          desc={mainPanelData}
          rightPanel={
            <Link
              to='#student-roster'
              data-automation='wizard-view-student-roster'
              className='wizard-student-roster-link cb-palette-blue5'
            >
              {rosterLinkText}
            </Link>
          }
        ></StepContentTab>
        <StudentRoster
          lastUpdated={lastUpdated}
          refetch={refetch}
          room={room}
          rosterHeading='Student Roster'
          showDirectionInfo={false}
          showInpage={true}
        />
      </>
    ),
    checkDesksDiagram: (
      <>
        <StepContentTab
          heading='Check Desks'
          showProgressBar={true}
          content={content}
          stepNum={stepNum}
          progressStepperMax={progressStepperMax}
          panelHeading='Check each student&rsquo;s desk for prohibited items.'
          rightPanel={
            <Link
              to='#student-roster'
              data-automation='wizard-view-student-roster'
              className='wizard-student-roster-link cb-palette-blue5'
            >
              {rosterLinkText}
            </Link>
          }
          desc={mainPanelData}
        ></StepContentTab>
        <StudentRoster
          lastUpdated={lastUpdated}
          refetch={refetch}
          room={room}
          rosterHeading='Student Roster'
          showDirectionInfo={false}
          showInpage={true}
        />
      </>
    ),
    accommodationsQuestion: (
      <>
        <StepContentPanel
          content={content}
          desc={mainPanelData}
          heading='Accommodations'
          illustrationContentVerticalJustify={true}
          img={<div className='psat-attendance-step-6-svg' />}
          imgDesc='Student Roster'
          nextUpStyle={true}
          progressStepperMax={progressStepperMax}
          room={room}
          showProgressBar={true}
          stepNum={stepNum}
        ></StepContentPanel>
      </>
    ),
    aboutBreaks: (
      <>
        <StepContentTab
          heading='About Breaks'
          content={content}
          showProgressBar={true}
          invertStyle={true}
          stepNum={stepNum}
          progressStepperMax={progressStepperMax}
          panelHeading='Read aloud to students.'
          rightPanel={
            <Link
              to='#student-roster'
              data-automation='wizard-view-student-roster'
              className='wizard-student-roster-link cb-palette-blue5'
            >
              {rosterLinkText}
            </Link>
          }
          desc={mainPanelData}
        ></StepContentTab>
        <StudentRoster
          lastUpdated={lastUpdated}
          refetch={refetch}
          room={room}
          rosterHeading='Student Roster'
          showDirectionInfo={false}
          showInpage={true}
        />
      </>
    ),
    startTesting: (
      <>
        <StepContentTab
          heading='Start Testing'
          showProgressBar={true}
          content={content}
          invertStyle={true}
          stepNum={stepNum}
          progressStepperMax={progressStepperMax}
          panelHeading='Read aloud to students.'
          rightPanel={
            <Link
              className='wizard-student-roster-link cb-palette-blue5'
              to='#student-roster'
              data-automation='wizard-view-student-roster'
            >
              {rosterLinkText}
            </Link>
          }
          desc={mainPanelData}
        ></StepContentTab>
        <StudentRoster
          lastUpdated={lastUpdated}
          refetch={refetch}
          room={room}
          rosterHeading='Student Roster'
          showDirectionInfo={false}
          showInpage={true}
        />
      </>
    ),
    startTheTest: (
      <>
        <StepContentTab
          heading='Start the Test'
          showProgressBar={true}
          content={content}
          invertStyle={true}
          stepNum={stepNum}
          progressStepperMax={progressStepperMax}
          panelHeading='Read this start code aloud, write it on the board, and click "Next Step" to monitor testing.'
          rightPanel={
            <Link
              className='wizard-student-roster-link cb-palette-blue5'
              to='#student-roster'
              data-automation='wizard-view-student-roster'
            >
              {rosterLinkText}
            </Link>
          }
          desc={
            <div>
              <div className='ap-exam-start-code'>
                <p className='mb-4' style={{ fontSize: '1.875rem', lineHeight: '4.375rem' }}>
                  Start Code:{' '}
                </p>
                <p className='mb-4' style={{ fontSize: '3.75rem', lineHeight: '4.375rem' }}>
                  {room.startPin}
                </p>
              </div>
              <div className='mt-4 text-center'>
                <div>{mainPanelData}</div>
              </div>
            </div>
          }
        ></StepContentTab>
        <StudentRoster
          lastUpdated={lastUpdated}
          refetch={refetch}
          room={room}
          rosterHeading='Student Roster'
          showDirectionInfo={false}
          showInpage={true}
        />
      </>
    ),
  };

  //flow does this
  if (isInSchoolAPEvent) {
    return {
      introStep: 1,
      outerStep: 8,
      steps: () => {
        switch (stepNum) {
          case 1:
            return stepComponents.takeAttendance;
          case 2:
            return stepComponents.readTheScript;
          case 3:
            return stepComponents.welcome;
          case 4:
            return stepComponents.checkDesksDiagram;
          case 5:
            return stepComponents.aboutBreaks;
          case 6:
            return stepComponents.startTesting;
          case 7:
            return stepComponents.startTheTest;
        }
      },
    };
  } else {
    return {
      introStep: 1,
      outerStep: 10,
      steps: () => {
        switch (stepNum) {
          case 1:
            return stepComponents.takeAttendance;
          case 2:
            return stepComponents.readTheScript;
          case 3:
            return stepComponents.welcome;
          case 4:
            return stepComponents.devicesAndMaterials;
          case 5:
            return stepComponents.checkDesksDiagram;
          case 6:
            return stepComponents.accommodationsQuestion;
          case 7:
            return stepComponents.aboutBreaks;
          case 8:
            return stepComponents.startTesting;
          case 9:
            return stepComponents.startTheTest;
        }
      },
    };
  }
}
function Attendance({ cmsContent, flowData, lastUpdated = new Date().toISOString(), refetch = () => {}, room = {} }) {
  const { stepNum } = flowData;
  const { orgEvent } = useStateValue();
  const rosterLinkText = 'Update Attendance and View Student Info';

  const { steps, introStep, outerStep } = getStepDefinitions({
    cmsContent,
    stepNum,
    room,
    lastUpdated,
    refetch,
    rosterLinkText,
    isInSchoolAPEvent: isInSchoolAPEvent(orgEvent),
  });

  // scroll to the roster if the URL has #student-roster
  useEffect(() => {
    if (window.location.hash === '#student-roster' && document.getElementById('student-roster')) {
      scrollTo('student-roster');
    }
  });

  useEffect(() => {
    const el = document?.getElementsByClassName('attendance-step-6-skipLink')?.[0];
    if (el) {
      const hrefFirstPartForRoomId = `/rooms/get/${room?.id}`;
      if (!el.href.match(`${room?.id}`)) {
        el.href = `${hrefFirstPartForRoomId}${el.pathname}`;
      }
    }
  });

  function testStarted() {
    const testStartedContent = cmsContent?.steps?.find((n) => n.step_number === outerStep);
    const testStartedText = <div dangerouslySetInnerHTML={createCleanMarkup(testStartedContent?.main_panel_content)} />;
    const content = { main_panel_content: testStartedText };
    return (
      <StepContentPanel
        room={room}
        heading='Test Started'
        showStepCompletePanel={true}
        showProgressBar={false}
        content={content}
        desc={testStartedText}
        img={<div className='setupComplete-svg step-illustration-img mt-4' />}
        imgDesc='You&rsquo;re ready to monitor student testing.'
      ></StepContentPanel>
    );
  }

  function navigation() {
    const prevButton =
      stepNum === introStep ? (
        <Link className='cb-btn cb-btn-sm cb-btn-light' to={`/rooms/get/${room?.id}/flow/setup/step/7`}>
          Back
        </Link>
      ) : stepNum > introStep ? (
        <Link
          className='cb-btn cb-btn-sm cb-btn-light'
          to={`/rooms/get/${room?.id}/flow/attendance/step/${stepNum - 1}`}
        >
          Back
        </Link>
      ) : (
        <></>
      );

    const nextButton =
      stepNum === outerStep ? (
        <Link
          className='cb-btn cb-btn-sm cb-btn-yellow ml-auto'
          data-automation='nav-nextStep-Link'
          to={`/rooms/get/${room?.id}/flow/monitor/step/1`}
        >
          Next Step
        </Link>
      ) : (
        <Link
          className='cb-btn cb-btn-sm cb-btn-yellow ml-auto'
          data-automation='nav-nextStep-Link'
          to={`/rooms/get/${room?.id}/flow/attendance/step/${stepNum + 1}`}
        >
          {stepNum >= introStep && stepNum < outerStep && 'Next Step'}
        </Link>
      );

    return <WizardNav prevButton={prevButton} nextButton={nextButton}></WizardNav>;
  }

  return (
    <>
      {stepNum === outerStep && testStarted()}
      {stepNum >= introStep && stepNum < outerStep && steps()}
      <HelpNav />
      {navigation()}
    </>
  );
}

Attendance.propTypes = {
  cmsContent: PropTypes.object,
  flowData: PropTypes.object,
  lastUpdated: PropTypes.string,
  refetch: PropTypes.func,
  room: PropTypes.object,
};

export default Attendance;
