import React, { useEffect, useState, useCallback } from 'react';
import { ApolloProvider } from '@apollo/client';
import { GET_LOGIN_USER } from './apollo/queries';
import { getClient } from './apollo/configuration';
import { hasAdminAccess } from './utils/user';
import { initialAppState } from './context/initialState';
import { isInSchoolEvent } from './utils/event';
import { mergeFeatureFlags } from './utils/featureFlags';
import { ResizeProvider } from './context/ResizeContext';
import { TourContextProvider } from './components/common/productTour';
import { useLocation } from 'react-router-dom';
import { useStateDispatch, useStateValue } from './context/AppContext';
import AppRouter from './AppRouter';
import CenterInfo from './components/nonDigital/landing/CenterInfo';
import DigitalErrorPage from './components/digital/error/ErrorPage';
import EmergencyNotification from './components/common/emergency/EmergencyNotification';
import ErrorPage from './components/nonDigital/error/ErrorPage';
import get from 'lodash/get';
import Legal from './components/common/legal/Legal';
import LocalNav from './components/ui/navigation/LocalNav';
import Login from './components/common/login/Login';
import Signup from './components/common/signup/Signup';
import Spinner from './components/ui/loading/SpinnerWrapper';
import StatusPanel from './components/common/status/StatusPanel';
import OrgEventQueryContainer from './components/nonDigital/landing/OrgEventQueryContainer';
import { parseJWTToken, useAuth } from './utils/auth';

const ApolloApp = () => {
  const [client] = useState(getClient());
  const [loadingUser, setLoadingUser] = useState(false);
  const { orgEvent, user } = useStateValue();
  const dispatch = useStateDispatch();
  const { pathname } = useLocation();

  const fetchUser = useCallback(async () => {
    setLoadingUser(true);
    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: GET_LOGIN_USER,
      });

      // Get login user
      const loginUser = get(data, 'viewer');

      // Get and process feature flags
      const evidentlyFeatures = get(data, 'features');
      const features = mergeFeatureFlags(
        evidentlyFeatures,

        loginUser.site.event?.featureFlags
      );

      if (loginUser) {
        dispatch({
          orgEvent: {
            asmtEventId: loginUser.site.event.asmtEventId,
            asmtEventTypeCd: loginUser.site.event.asmtEventTypeCd,
            asmtId: loginUser.site.event.asmtId,
            closure: loginUser.site.event.closure,
            dapInd: !!loginUser?.site?.event?.dapInd,
            eventDisplayDescr: loginUser.site.event.eventDisplayDescr,
            eventEndDt: loginUser.site.event.eventEndDt,
            eventStartDt: loginUser.site.event.eventStartDt,
            eventTitle: loginUser.site.event.eventTitle,
            featureFlags: loginUser.site.event.featureFlags,
            orgId: loginUser.site.orgId,
            siteId: loginUser.site.id,
            enableEssaySection: loginUser.site.enableEssaySection,
            tdtkAsmtType: loginUser.site.event.tdtkAsmtType,
            testCenterCd: loginUser.site.testCenterCd,
            title: loginUser.site.event.title,
            //(ref: admin_event_payload_do_not_display_special_only)
            doNotDisplaySpecialUseOnlyDt: loginUser.site.event.doNotDisplaySpecialUseOnlyDt,
          },
          user: {
            active: loginUser.active,
            confirmed: loginUser.confirmed,
            id: loginUser.id,
            room: loginUser.room
              ? {
                  id: loginUser?.room?.id || '-1',
                  title: loginUser?.room?.title || '',
                }
              : null,
            role: loginUser.role,
            username: loginUser.username,
            welcomed: loginUser.welcomed,
          },
          features: features,
        });
        if (!loginUser.site.event?.tdtkAsmtType) {
          dispatch({
            globalError: <ErrorPage errorCode='data:missing_tdtkAsmtType' />,
          });
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      const res = get(e?.networkError, 'result.code');

      if (res === 'auth:missing_user_record' || res === 'auth:missing_admin_records') {
        dispatch({
          globalError: <ErrorPage errorCode='auth:missing_user_record' />,
          user: {
            missingUserRecord: true,
          },
        });
      } else {
        console.error('gatekeeper error', e);
        dispatch({
          globalError: <ErrorPage errorCode='500' />,
        });
      }
    }
    setLoadingUser(false);
  }, [client, dispatch]);

  useEffect(() => {
    // Fetch user every time siteId/orgId or asmtEventId changes. Triggered from OrgEventQueryContainer component
    if (orgEvent.asmtEventId && orgEvent.siteId && orgEvent.orgId) {
      fetchUser();
    }
  }, [orgEvent.asmtEventId, orgEvent.siteId, orgEvent.orgId, fetchUser]);

  if (!(orgEvent.siteId && orgEvent.orgId && orgEvent.asmtEventId) || pathname === '/event') {
    // They aren't in a specific event role.
    return (
      <ApolloProvider client={client}>
        <div className='container' id='main-content'>
          <OrgEventQueryContainer />
        </div>
      </ApolloProvider>
    );
  }

  if (!user.id) {
    return <Spinner />;
  }

  if (user.missingUserRecord) {
    // Try clearing their session?
    sessionStorage.removeItem('orgEvent');

    // Failed to fetch a user for some reason.
    return (
      <div className='container'>
        <ErrorPage errorCode='auth:missing_user_record' />
      </div>
    );
  }

  if (!user.confirmed) {
    let agreementType = 'legal';

    if (orgEvent?.dapInd) {
      agreementType = 'digitalLegal';
    }
    return (
      <ApolloProvider client={client}>
        <div className='container' id='main-content' tabIndex={-1}>
          <Legal legalAgreementType={agreementType} />
        </div>
      </ApolloProvider>
    );
  }

  if (!user.active) {
    return (
      <div className='container'>
        {orgEvent?.dapInd ? <DigitalErrorPage errorCode='inactive' /> : <ErrorPage errorCode='inactive' />}
      </div>
    );
  }

  if (
    (orgEvent?.dapInd && isInSchoolEvent(orgEvent)) ||
    (orgEvent?.dapInd && !hasAdminAccess(user?.rid, user?.role) && user?.room?.id)
  ) {
    /**
     * In-School Digital Experience for all user types AND Digital Proctor Experience route.
     */
    return (
      <ApolloProvider client={client}>
        <ResizeProvider>
          <LocalNav />
          <div className='center-info py-2 hidden-md-up'>
            <CenterInfo />
          </div>
          <EmergencyNotification />
          <div id='main-content' tabIndex={-1}>
            <AppRouter />
          </div>
        </ResizeProvider>
      </ApolloProvider>
    );
  }

  // default view if user data has been fetched
  if (user.id) {
    return (
      <ApolloProvider client={client}>
        <TourContextProvider>
          <ResizeProvider>
            <LocalNav />
            <div className='center-info py-2 hidden-md-up'>
              <CenterInfo />
            </div>
            <EmergencyNotification />
            <StatusPanel />
            <div className={`${orgEvent?.dapInd ? '' : 'container'}`} id='main-content' tabIndex={-1}>
              <AppRouter />
            </div>
          </ResizeProvider>
        </TourContextProvider>
      </ApolloProvider>
    );
  }

  return null;
};

function GateKeeper() {
  const loginStatus = useAuth();
  const location = useLocation();

  // Global App state.
  const { globalError } = useStateValue();
  const dispatch = useStateDispatch();

  useEffect(() => {
    if (loginStatus.loggedIn) {
      dispatch({
        user: {
          token: parseJWTToken(),
        },
      });
    }
  }, [loginStatus.loggedIn, dispatch]);

  useEffect(() => {
    if (loginStatus.loggedOut) {
      dispatch({ ...initialAppState });
      // Clear out any saved info. Not everything, since we don't want to clear potential magic link session.
      sessionStorage.removeItem('orgEvent');
      sessionStorage.removeItem('studentDynamicColumns');
      sessionStorage.removeItem('studentFilters');
      sessionStorage.removeItem('studentPageSize');
      sessionStorage.removeItem('studentSearch');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('wasWelcomed');
    }
  }, [loginStatus.loggedOut, dispatch]);

  // signup flow will deal with logged in status separately
  if (location.pathname === '/signup') {
    return (
      <div className='container' id='main-content'>
        <Signup />
      </div>
    );
  }

  if (loginStatus.loading) {
    return <Spinner />;
  }

  // catapult failed
  if (loginStatus.error) {
    return <ErrorPage />;
  }

  if (loginStatus.loggedOut) {
    return (
      <div className='container' id='main-content'>
        <Login />
      </div>
    );
  }

  if (globalError) {
    return (
      <div className='container' id='main-content'>
        {globalError}
      </div>
    );
  }

  if (loginStatus.loggedIn) {
    return <ApolloApp />;
  }

  return null;
}

export default GateKeeper;
