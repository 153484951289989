/**
 * Common Utils
 */
import { formatPhoneNumber } from 'react-phone-number-input';
import { orderBy } from 'natural-orderby';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import mergeWith from 'lodash/mergeWith';
import moment from 'moment';
import DOMPurify from 'dompurify';

export function createCleanMarkup(dangerousHtml) {
  // This should purify any content coming from the CMS.
  return { __html: DOMPurify.sanitize(dangerousHtml, { ADD_ATTR: ['target'] }) };
}

export function timeFromNow(minutes) {
  // Returns a date in the future x minutes from now.
  return new Date(new Date().getTime() + minutes * 60 * 1000);
}

export function sortItems(items = [], fields = {}, sortStrategy = 'natural') {
  if (sortStrategy === 'alphanumeric') {
    return alphaNumericSort(items, fields);
  } else if (sortStrategy === 'natural') {
    return naturalSort(items, fields);
  } else {
    throw Error('unknown sorting strategy');
  }
}

function alphaNumericSort(items, fields) {
  return [...items].sort((a, b) => {
    let comparison = 0;
    fields.find((field) => {
      const aField = get(a, field.name) || '';
      const bField = get(b, field.name) || '';
      comparison = _compare(aField, bField, field.order);
      return comparison !== 0;
    });
    return comparison;
  });
}

function naturalSort(items, fields) {
  const fieldsParam = fields.map((field) => (v) => {
    let value = get(v, field.name) || '';

    // Check if we got an array of values.
    if (Array.isArray(value) && value.length) {
      value = value[0];
    }

    if (isString(value)) {
      value = value.toLowerCase();
    }

    return value;
  });

  const orderParam = fields.map((field) => field.order);
  return orderBy(items, fieldsParam, orderParam);
}

/**
 * Scroll from initY to 0 + offset.
 */
export function scrollTo(id) {
  const el = document.getElementById(id);
  if (el) {
    // Offset for local nav.
    const y = el.getBoundingClientRect().top + window.pageYOffset - 100;

    window.scrollTo({ top: y, behavior: 'smooth' });
    document.getElementById(id).focus();
  }
}

/**
 * Trap focus in an element, for example in a modal dialog while it is open .
 */
export function trapKeyboardFocus(id) {
  const element = document.getElementById(id);
  const KEY_CODE_MAP = {
    TAB: 9,
  };
  const focusableElements = element.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
  );

  if (focusableElements.length > 0) {
    const firstFocusableEl = focusableElements[0],
      lastFocusableEl = focusableElements[focusableElements.length - 1];

    firstFocusableEl.focus();

    const keyboardHandler = (e) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === KEY_CODE_MAP.TAB;

      if (!isTabPressed) {
        return;
      } else {
        if (e.shiftKey && document.activeElement === firstFocusableEl) {
          /* shift + tab */
          e.preventDefault();
          lastFocusableEl.focus(); // add focus for the last focusable element
        } else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
          /* tab */
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          e.preventDefault();
          firstFocusableEl.focus(); // add focus for the first focusable element
        }
      }
    };
    element.addEventListener('keydown', keyboardHandler);
  }
}

/**
 * Return the number of seats available, taking no-shows into consideration.
 */
export function seatsAvailable(room) {
  let roomOccupancy = 0;

  if (room && room.capacity && room.students) {
    // Get the total room occupation by subtracting absent students.
    roomOccupancy = room.students.filter((student) => !student.absent && !student.deniedEntry).length;

    return room.capacity - roomOccupancy;
  } else if (room && room.capacity) {
    return room.capacity;
  } else {
    return 0;
  }
}

/**
 * This formats a start date and end date per College Board standards.
 * @param (startDate) - Format : YYYY-MM-DD
 * @param (endDate) - Format : YYYY-MM-DD
 */
export function parseDateFormat(s, e) {
  const startDate = moment.utc(s, 'YYYY-MM-DD');
  const endDate = moment.utc(e, 'YYYY-MM-DD');
  let combinedDate = startDate;

  // Compare the two end years to see if they are the same.
  if (startDate.year() === endDate.year()) {
    // Compare the two months.
    if (startDate.month() === endDate.month()) {
      // Check the two days.
      if (startDate.day() === endDate.day()) {
        // These are the same date, just return the start date.
        combinedDate = startDate.format('MMM D, YYYY');
      } else {
        // These have the same year/month but different days.
        combinedDate = `${startDate.format('MMM D')}—${endDate.format('D, YYYY')}`;
      }
    } else {
      // They have different months.
      combinedDate = `${startDate.format('MMM D')}–${endDate.format('MMM D, YYYY')}`;
    }
  } else {
    // They have different years.
    combinedDate = `${startDate.format('MMM D, YYYY')}–${endDate.format('MMM D, YYYY')}`;
  }

  if (startDate.isoWeekday() === 7) {
    return 'Sunday, ' + combinedDate;
  } else {
    return combinedDate;
  }
}

export function breakOutQueryString(q) {
  const queryObj = {};

  if (q.indexOf('?') !== -1) {
    // ?un=321&id=1324 converts to:
    // ["un=321", "id=1324"] converts to:
    // {un: 321, id: 1324}
    q.split('?')[1]
      .split('&')
      .forEach((qs) => {
        queryObj[qs.split('=')[0]] = qs.split('=')[1];
      });
  }
  return queryObj;
}

export function localStateReducer(previousState, newState) {
  function customMerge(objValue, srcValue) {
    /**
     * Check if the matching object is missing any properties and just delete them.
     * e.g. {a: 1, b: 1} merged with just {a: 2} should only return {a: 2}
     */

    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
      return [...srcValue];
    } else if (typeof objValue === 'object' && objValue !== null && typeof srcValue === 'object' && srcValue !== null) {
      return {
        ...srcValue,
      };
    } else {
      return srcValue;
    }
  }

  return {
    ...mergeWith(previousState, newState, customMerge),
  };
}

export function parsePhoneNumbers(phone = []) {
  const formattedPhone = (num, type) => {
    return num
      ? num.indexOf('1') === 0
        ? formatPhoneNumber(`+${num}`, type)
        : num.indexOf('+') !== 0
        ? formatPhoneNumber(`+1${num}`, type)
        : formatPhoneNumber(num, type)
      : '';
  };

  /**
   * We are going on the assumption that data will look like an array of objects.
   * [
   *  {
   *    type: 'mobile',
   *    phoneNumber: '+13334445555',
   *    ext: 'x123',
   *  }
   * ]
   */

  const defaultNumbers = [
    {
      phoneNumber: formattedPhone(phone, 'International'),
      type: 'mobile',
    },
    {
      phoneNumber: '',
      type: 'home',
    },
    {
      ext: '',
      phoneNumber: '',
      type: 'work',
    },
  ];

  let staffPhoneParsed = {};

  try {
    staffPhoneParsed = JSON.parse(phone);

    // Ensure it's an array.
    if (Array.isArray(staffPhoneParsed) && staffPhoneParsed.length > 0) {
      // Loop through and add national style formatting.
      return staffPhoneParsed.map((num) => {
        const ph = {
          phoneNumber: formattedPhone(num.phoneNumber, 'International').replace(/[ ]/g, '') || '',
          type: num.type,
        };

        if (num.type === 'work') {
          ph.ext = num.ext || '';
        }

        return ph;
      });
    } else return defaultNumbers;
  } catch (e) {
    // This is not a valid JSON object. Let's just pass the cleaned up default.
    return defaultNumbers;
  }
}

// Stops a default click action. Used for stuff like popovers.
export function clickHandler(e) {
  e && e.preventDefault && e.preventDefault();
}

export function multiDayDisplay(student = {}) {
  if (student.multiDayInd && student.testPackageSeq > 0) {
    return ` - Day ${student.testPackageSeq}`;
  } else {
    return '';
  }
}

type NameFormats = 'l' | 'fm' | 'fml' | 'lfm';

export function formatStudentName(student = {}, format: NameFormats = 'lfm') {
  switch (format) {
    case 'l':
      return student.candLastName ? `${student.candLastName}${multiDayDisplay(student)}` : '';
    case 'fm':
      return `${student.candFirstName ? student.candFirstName : ''}${
        student.candMidInit ? ` ${student.candMidInit}.` : ''
      }`;
    case 'fml':
      // First name, middle initial, last name.
      return `${student.candFirstName ? `${student.candFirstName} ` : ''}${
        student.candMidInit ? `${student.candMidInit}. ` : ''
      }${student.candLastName ? `${student.candLastName}${multiDayDisplay(student)}` : ''}`;
    default:
      // By default, last name, first name, middle initial.
      return `${student.candLastName ? student.candLastName : ''}${
        student.candLastName && student.candFirstName ? ', ' : ''
      }${student.candFirstName ? student.candFirstName : ''}${
        student.candMidInit ? ` ${student.candMidInit}.` : ''
      }${multiDayDisplay(student)}`;
  }
}

export function getStudentInitials(student) {
  const first = get(student, 'candFirstName[0]', null);
  const mid = get(student, 'candMidInit[0]', null);
  const last = get(student, 'candLastName[0]', null);
  let initials = '';

  if (first) {
    initials += `${first}. `;
  }

  if (mid) {
    initials += `${mid}. `;
  }

  if (last) {
    initials += `${last}.`;
  }

  return initials;
}

export function toLocaleUTCDateString(date, options) {
  const timeDiff = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.valueOf() + timeDiff);
  return adjustedDate.toLocaleDateString(undefined, options);
}

/**
 * Reducer used to simulate the good old days of this.setState();
 */
export function reducer(state, action) {
  // Exception for empty arrays not replacing arrays.
  function customizer(objValue, srcValue) {
    if (isArray(objValue) && isArray(srcValue) && !srcValue.length) {
      return [];
    }
  }

  const deepCopy = {
    ...mergeWith(state, action, customizer),
  };

  return deepCopy;
}

// remove <br /> tags from a string
export const stripBrs = (s: string) => {
  return s?.replace(/<br.+>/gi, '');
};

export const setCheckedProp = (ref, newValue) => {
  if (typeof ref?.current?.checked === 'boolean') {
    ref.current.checked = newValue;
  }
};

export function fuzzySearch(term: string, text: string) {
  // Build Regex String
  let matchTerm = '.*';

  // Split all the search terms
  const terms = term.split(' ');

  for (let i = 0; i < terms.length; i++) {
    matchTerm += '(?=.*' + terms[i] + '.*)';
  }

  matchTerm += '.*';

  // Convert to Regex
  // => /.*(?=.*TERM1.*)(?=.*TERM2.*).*/
  const matchRegex = new RegExp(matchTerm?.toUpperCase());
  return text?.toUpperCase().match(matchRegex);
}

/**
 * Any arbitrary callback.
 *
 * @callback anyCallback
 */

/**
 * @typedef {Object} SetRandomIntervalObject
 * @property {anyCallback} clearInterval Clears the random interval
 */

/**
 * Convert a Date object into a localized iso8601 timestamp
 */
export const getLogTimestamp = (date = new Date()) => {
  const offset = date.getTimezoneOffset();
  const offsetAbs = Math.abs(offset);
  const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString();
  return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(Math.floor(offsetAbs / 60)).padStart(
    2,
    '0'
  )}:${String(offsetAbs % 60).padStart(2, '0')}`;
};
